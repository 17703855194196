import React, { FC } from 'react';

import { TRentProduct } from '../../types';
import { ProductsSlider } from '../products-slider';

import { StyledPopularProducts } from './styled';

type TProps = {
    products: TRentProduct[];
    idSlider?: string;
};

export const PopularProducts: FC<TProps> = ({ products, idSlider = '' }) => {
    return (
        <StyledPopularProducts>
            <ProductsSlider id={idSlider} products={products} />
        </StyledPopularProducts>
    );
};

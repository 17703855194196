import React from 'react';

import { TSvgIcon } from '../types';

export const BasketFullIcon: TSvgIcon = ({ width = 25, height = 24 }): JSX.Element => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.75 18C6.65 18 5.76 18.9 5.76 20C5.76 21.1 6.65 22 7.75 22C8.85 22 9.75 21.1 9.75 20C9.75 18.9 8.85 18 7.75 18ZM1.75 2V4H3.75L7.35 11.59L6 14.04C5.84 14.32 5.75 14.65 5.75 15C5.75 16.1 6.65 17 7.75 17H19.75V15H8.17C8.03 15 7.92 14.89 7.92 14.75L7.95 14.63L8.85 13H16.3C17.05 13 17.71 12.59 18.05 11.97L21.63 5.48C21.71 5.34 21.75 5.17 21.75 5C21.75 4.45 21.3 4 20.75 4H5.96L5.02 2H1.75ZM17.75 18C16.65 18 15.76 18.9 15.76 20C15.76 21.1 16.65 22 17.75 22C18.85 22 19.75 21.1 19.75 20C19.75 18.9 18.85 18 17.75 18Z"
                fill="#00D9DE"
            />
        </svg>
    );
};

const formatNumberWithSpaces = (num: number | null): string => {
    if (num === null) {
        return '0';
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const customMathCeil = (num?: number | string): number => {
    if (!num) {
        return 0;
    }

    if (typeof num === 'string') {
        const newNum = Number(num);

        if (!Number.isNaN(newNum)) {
            return Math.ceil(newNum * 100) / 100;
        }

        return 0;
    }

    return Math.ceil(num * 100) / 100;
};

export const numbers = {
    formatNumberWithSpaces,
    customMathCeil,
};

import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { ProductPriceContainer, ProductPriceTitle, ProductPriceDiscount, ProductPriceValue, RentRange } from './styled';

export type TProps = {
    title: string;
    suffix?: string;
    value: number | null;
    discount?: string;
};

export const ProductPrice: FC<TProps> = ({ title, value, discount = '', suffix }) => {
    const { t } = useTranslate();

    return (
        <ProductPriceContainer>
            <ProductPriceTitle>
                {t(title)}
                {discount && Number(discount) > 0 && <ProductPriceDiscount>{`-${discount}%`}</ProductPriceDiscount>}
            </ProductPriceTitle>

            <ProductPriceValue>
                {suffix && <RentRange>{t(suffix) + ' '}</RentRange>}
                {value && t('pricePerDay', { value })}
            </ProductPriceValue>
        </ProductPriceContainer>
    );
};

import { Grid } from 'antd';
import React, { FC, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Heading, THeading } from '@/shared/ui/heading';

import { StyledPageSection, StyledPageSectionTitleContainer } from './styled';

const { useBreakpoint } = Grid;

type TProps = {
    title: string;
    linkComponent?: ReactNode;
    tagTitle?: THeading;
    className?: string;
    children: ReactNode;
};

export const PageSection: FC<TProps> = ({ linkComponent, title, className, children, tagTitle = 'h2' }) => {
    const { t } = useTranslate();
    const theme = useTheme();

    return (
        <StyledPageSection className={className}>
            <StyledPageSectionTitleContainer>
                <Heading as={tagTitle} size={theme.fontSize.xl} sizeMobile="lg">
                    {t(title)}
                </Heading>
                {linkComponent}
            </StyledPageSectionTitleContainer>
            {children}
        </StyledPageSection>
    );
};

type TAdditionalProps = TProps & {
    changeBy: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    deskTitle: string;
    mobTitle: string;
};

export const PageSectionWithAnotherTitle: FC<TAdditionalProps> = ({ changeBy, deskTitle, mobTitle, ...props }) => {
    const screen = useBreakpoint();
    const title = screen[changeBy] ? deskTitle : mobTitle;

    return <PageSection {...props} title={title} />;
};

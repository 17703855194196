import Image from 'next/image';
import React, { FC } from 'react';

import { useAppSelector } from '@/store/hooks';

import { IMAGE_ALT_CUT_LENGTH, DEFAULT_IMAGE_SRC } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { DropdownModalContainer } from '@/shared/ui/dropdown-modal';
import { cutString } from '@/shared/utils/string';

import { StyledMiniatureList, StyledMiniatureListItem, StyledMiniatureContainer } from './styled';

export const BasketMiniature: FC = () => {
    const { t } = useTranslate();

    const { itemsBasket } = useAppSelector((state) => state.basket);

    return (
        <StyledMiniatureContainer>
            <DropdownModalContainer textBtn={t('В корзину')} title="Корзина" href="/basket" withBg={false}>
                <StyledMiniatureList>
                    {itemsBasket.map((el, i) => (
                        <StyledMiniatureListItem key={i}>
                            <Image
                                src={el.photos?.[0]?.image ?? DEFAULT_IMAGE_SRC}
                                width={42}
                                height={42}
                                alt={cutString(el.name, IMAGE_ALT_CUT_LENGTH)}
                            />
                            <span className="name"> {el.name} </span>
                        </StyledMiniatureListItem>
                    ))}
                </StyledMiniatureList>
            </DropdownModalContainer>
        </StyledMiniatureContainer>
    );
};

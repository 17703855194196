import { styled, css } from 'styled-components';

import { Button } from '@/shared/ui/button';
import { mq } from '@/shared/ui/styled';

import { ProductPriceContainer } from '../product-price/styled';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    padding: 12px;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    & a {
        &:hover {
            color: inherit;
        }
    }

    ${mq.gt.sm} {
        padding: 0 16px 16px;
    }
`;

export const ProductTitle = styled.h3`
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: 500;
    line-height: 1.3;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 47px;
`;

export const StyledProductCardFullImageCss = css`
    flex-direction: column;
    align-items: initial;

    & > ${ProductTitle} {
        margin-left: 0;
        margin-top: 16px;
    }

    & > img {
        width: 100%;
        height: 204px;
        object-fit: cover;
    }
`;

export const ProductImageContainer = styled.div`
    display: flex;
    align-items: center;

    .block {
        height: 204px;
        padding: 12px;
        display: flex;
        align-items: center;

        .block__image {
            height: 160px;
            width: 190px;
            margin: 0 auto;
            position: relative;

            img {
                object-fit: contain;
            }
        }
    }

    & > ${ProductTitle} {
        margin-left: 16px;
    }
    ${StyledProductCardFullImageCss};
`;

export const ProductPricesContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    & > ${ProductPriceContainer} + ${ProductPriceContainer} {
        margin-left: 12px;
    }

    ${mq.gt.sm} {
        flex-direction: column;

        & > ${ProductPriceContainer} + ${ProductPriceContainer} {
            margin-left: 0;
            margin-top: 12px;
        }
    }
`;

export const ProductButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .right {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
`;

export const ProductHint = styled.p`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-top: 16px;

    & > svg {
        margin-right: 10px;
    }
`;

export const ProductTextButton = styled(Button).attrs({
    fontSize: 'xs',
    variant: 'primary',
})`
    border-radius: 4px;
    font-weight: 700;

    &.in-basket {
        background: ${({ theme }) => theme.color.dark};
        color: ${({ theme }) => theme.color.secondary};
    }

    &.out-of-stock {
        background: ${({ theme }) => theme.color.neutral200};
        color: ${({ theme }) => theme.color.neutral500};
    }
`;

export const ProductIconButton = styled(Button)`
    background-color: transparent !important;
    padding: 0;
`;

export const RentText = styled.p`
    font-size: 12px;
    font-weight: 500;
    margin: 16px 0 12px;
`;

export const RentRange = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: #818c99;
`;

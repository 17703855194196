import React, { FC } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { TRentProduct, ProductCard } from '@/entities/product';

import { ArrowIcon } from '@/shared/ui/icons';

import {
    StyledProductsSlider,
    StyledProductSliderContainer,
    StyledProductSliderPrevNavButton,
    StyledProductSliderNextNavButton,
} from './styled';

type TProps = {
    products: TRentProduct[];
    id?: string;
};

export const ProductsSlider: FC<TProps> = ({ products = [], id = '' }) => {
    const classNextSwipeButton = id ? `product-swiper-button-next-${id}` : 'product-swiper-button-next';
    const classPrevSwipeButton = id ? `product-swiper-button-prev-${id}` : 'product-swiper-button-prev';

    return (
        <StyledProductsSlider>
            <StyledProductSliderContainer>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView="auto"
                    navigation={{
                        nextEl: `.${classNextSwipeButton}`,
                        prevEl: `.${classPrevSwipeButton}`,
                    }}
                >
                    {products?.map((productProps) => (
                        <SwiperSlide key={productProps.id.toString()}>
                            <ProductCard product={productProps} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <StyledProductSliderPrevNavButton className={classPrevSwipeButton}>
                    <ArrowIcon />
                </StyledProductSliderPrevNavButton>
                <StyledProductSliderNextNavButton className={classNextSwipeButton}>
                    <ArrowIcon />
                </StyledProductSliderNextNavButton>
            </StyledProductSliderContainer>
        </StyledProductsSlider>
    );
};

import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { useAppSelector } from '@/store/hooks';

import { PRODUCT_URL } from '@/shared/constants/paths';
import { IMAGE_ALT_CUT_LENGTH, DEFAULT_IMAGE_SRC } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { BasketFullIcon, Basket2Icon, DangerIcon, HeartIcon, SuccessIcon } from '@/shared/ui/icons';
import { getPercentDiff } from '@/shared/utils/get-percent-diff';
import { cutString } from '@/shared/utils/string';

import { useBasketActions } from '../../hooks/use-basket-actions';
import { useActionsProducts } from '../../hooks/use-favorite-products';
import { TRentProduct } from '../../types';
import { BuyoutBlock } from '../buyout-block';
import { ProductPrice } from '../product-price';

import * as Styled from './styled';

export type ProductCardProps = {
    title: string;
    image: string;
    prices: {
        dailyPrice: {
            title: string;
            suffix?: string;
            value: number | null;
            discount?: string;
        };
        monthlyPrice: {
            title: string;
            suffix?: string;
            value: number | null;
            discount?: string;
        };
    };
    className?: string;
    href?: string;
};

type TProps = {
    className?: string;
    product: TRentProduct;
};

export const ProductCard: FC<TProps> = ({ product, className }) => {
    const { t } = useTranslate();
    const theme = useTheme();
    const { storageCity, id, photos, prices, status, name } = product;
    const { cityUser } = useAppSelector((state) => state.user);
    const { handleAddProductToFavorite, favoriteProducts } = useActionsProducts({ product });
    const { handleAddProductToBasket, basketProducts, onReserveProduct } = useBasketActions({ product });
    const { priceForSevenDays, priceForThirtyDays, priceForTwelveMonths } = prices;

    if (!product) return <></>;

    const addProductToBasket = () => {
        handleAddProductToBasket();
    };

    return (
        <Styled.Wrapper>
            <Link href={`${PRODUCT_URL}/${id}`}>
                <Styled.ProductImageContainer className={className}>
                    <div className="block">
                        <div className="block__image">
                            <Image
                                fill
                                src={photos?.[0]?.image ?? DEFAULT_IMAGE_SRC}
                                alt={cutString(name, IMAGE_ALT_CUT_LENGTH)}
                            />
                        </div>
                    </div>
                    <Styled.ProductTitle>{name}</Styled.ProductTitle>
                </Styled.ProductImageContainer>
                {product.is_buyout && priceForTwelveMonths && (
                    <>
                        <BuyoutBlock priceForTwelveMonths={priceForTwelveMonths} />
                        <Styled.RentText> {t('Или взять в аренду')}</Styled.RentText>
                        <Styled.RentRange> {t('от 1 до 30 дней')}</Styled.RentRange>
                        <ProductPrice suffix="от" title="" value={priceForThirtyDays} />
                    </>
                )}
                {!product.is_buyout && (
                    <Styled.ProductPricesContainer>
                        <ProductPrice
                            title="От месяца"
                            value={priceForThirtyDays}
                            discount={getPercentDiff(priceForSevenDays, priceForThirtyDays)}
                        />
                        <ProductPrice title="От 7 дней" value={priceForSevenDays} />
                    </Styled.ProductPricesContainer>
                )}
                <Styled.ProductHint>
                    {storageCity === cityUser.name ? (
                        <>
                            <SuccessIcon width={16} color={theme.color.primary} />
                            {t('Товар находится в вашем городе')}
                        </>
                    ) : (
                        <>
                            <DangerIcon width={16} />
                            {t('Товар находится в г.')} {storageCity}
                        </>
                    )}
                </Styled.ProductHint>

                <Styled.ProductButtonsContainer onClick={(e) => e.preventDefault()}>
                    <div>
                        {status !== 'active' && !basketProducts?.includes(id) && (
                            <Styled.ProductTextButton className="out-of-stock">
                                {t('Нет в наличии')}
                            </Styled.ProductTextButton>
                        )}

                        {status === 'active' && (
                            <Styled.ProductTextButton isAwaitLoading onClick={onReserveProduct}>
                                {t('Забронировать')}
                            </Styled.ProductTextButton>
                        )}
                    </div>
                    <div className="right">
                        {status === 'active' && (
                            <Styled.ProductIconButton onClick={addProductToBasket}>
                                {basketProducts?.includes(id) ? <BasketFullIcon /> : <Basket2Icon />}
                            </Styled.ProductIconButton>
                        )}
                        <Styled.ProductIconButton onClick={handleAddProductToFavorite}>
                            <HeartIcon color={theme.color.primary} filled={favoriteProducts?.includes(id)} />
                        </Styled.ProductIconButton>
                    </div>
                </Styled.ProductButtonsContainer>
            </Link>
        </Styled.Wrapper>
    );
};

import { GTM_TOGGLE, YANDEX_METRIC_EVENT_KEY } from '@/shared/constants/settings';

type TUseMetricReturned = {
    postMetric: (metricEvent: string) => void;
};

export const useMetric = (): TUseMetricReturned => {
    const postYandexMetricEvent = (metricEvent: string) => {
        if (typeof ym === 'undefined') {
            // eslint-disable-next-line no-console
            console.error('Yandex Metrica not initialized');

            return;
        }

        ym(YANDEX_METRIC_EVENT_KEY, 'reachGoal', metricEvent);
    };

    const postGoggleMetricEvent = (metricEvent: string) => {
        if (typeof dataLayer === 'undefined') {
            // eslint-disable-next-line no-console
            console.error('Google Metric not initialized');

            return;
        }

        dataLayer.push({ event: metricEvent });
    };

    const postMetric = (metricEvent: string) => {
        if (GTM_TOGGLE) {
            postGoggleMetricEvent(metricEvent);
        }

        postYandexMetricEvent(metricEvent);
    };

    return { postMetric };
};
